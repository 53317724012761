<template>
  <div>
    <BModal
      id="modal-ajukan-retur"
      centered
      modal-class="primary"
      hide-header
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
    >
      <b-row class="justify-content-end">
        <b-button
          class="btn-icon"
          size="sm"
          variant="flat-dark"
          @click="closePopupClaimRetur"
        >
          <feather-icon
            icon="XCircleIcon"
            size="25"
          />
        </b-button>
      </b-row>

      <b-row class="text-center justify-content-center mb-1">
        <h3 class="text-black">
          <strong>
            Bukti Chat
          </strong>
        </h3>
      </b-row>

      <b-row class="justify-content-center text-center mx-2 px-2 mb-1">
        <span class="text-black">
          Untuk memproses claim ongkir retur, mohon upload <strong>bukti chat terupdate</strong> bahwa customermu
          masih mau bayar. Unggah bukti di bawah ini ya :
        </span>
      </b-row>

      <b-row class="justify-content-center mx-2 py-2">
        <div
          v-if="fileProv.length === 0"
          class="w-100 dragzone__prove__file"
          @dragover.prevent
          @drop.stop.prevent
        >
          <div
            class="mb-2"
            @drop.stop.prevent="dragFile"
          >
            <b-row class="justify-content-center my-2">
              <b-img src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/gallery.svg" />
            </b-row>
            <input
              id="form__input__file__claim__retur"
              accept="image/*"
              type="file"
              multiple
              hidden
              @input="handleFileInput($event)"
            >
            <b-row class="justify-content-center">
              <h5>
                <strong>
                  Drop foto disini, atau <label for="form__input__file__claim__retur">
                    <h5 class="text-primary cursor-pointer">
                      <strong>
                        pilih
                      </strong>
                    </h5>
                  </label>
                </strong>
              </h5>
            </b-row>
            <b-row class="justify-content-center text-center">
              <span style="color: #C2C2C2;">file berupa JPG, JPEG2000, PNG <span class="text-primary">Max
                2MB</span></span>
            </b-row>
            <div v-if="fileProv.length > 0">
              <ul
                v-for="(file, index) in fileProv"
                :key="index+1"
              >
                <li>{{ file.url }}</li>
              </ul>
            </div>
          </div>
        </div>

        <!-- Preview to upload -->
        <div
          v-if="fileProv.length > 0"
          class="w-100 dragzone__prove__file"
        >
          <div
            class="d-flex align-items-start flex-wrap"
          >
            <div
              v-for="(file, index) in fileProv"
              :key="index+1"
            >
              <b-overlay
                :show="file.progressBar"
                spinner-variant="primary"
                variant="light"
                blur="0"
                opacity=".5"
                spinner-small
              >
                <div class="m-1 wrapper__prove__preview">
                  <div>
                    <b-img
                      src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/close.svg"
                      class="cursor-pointer x__icon__retur bg-primary rounded-circle w-5"
                      @click="removeFile(index)"
                    />
                  </div>
                  <div>
                    <a
                      :href="file.url"
                      target="_blank"
                    >
                      <b-img
                        :src="file.url"
                        width="75"
                      />
                    </a>
                  </div>
                </div>
              </b-overlay>
            </div>
          </div>
        </div>
      </b-row>

      <b-row
        v-if="fileProv.length > 0"
        class="mx-1 mb-1"
      >
        <b-col cols="6">
          <input
            id="form__file"
            type="file"
            accept="image/*"
            multiple
            hidden
            @input="handleFileInput($event)"
          >
          <label
            for="form__file"
            class="btn btn-outline-primary btn-block"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
              style="display: inline-block !important;"
            />
            <span class="text-black">Tambah File</span>
          </label>
        </b-col>
        <b-col cols="6">
          <b-button
            :disabled="isDisableSubmitClaimRetur"
            variant="primary"
            block
            @click="submitClaimRetur"
          >
            <b-spinner
              v-if="isUploading"
              small
            />
            Claim Retur
          </b-button>
        </b-col>
      </b-row>
    </BModal>
  </div>
</template>

<script>
export default {
  props: {
    orderId: {
      type: Number,
      required: true,
    },
    ticketId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fileProv: [],
      isDisableSubmitClaimRetur: false,
      isUploading: false,

      maxFileSize: 2000000,
    }
  },
  methods: {
    closePopupClaimRetur() {
      this.fileProv = []
      this.isDisableSubmitClaimRetur = false
      this.fileSizeExceeded = false
      this.$bvModal.hide('modal-ajukan-retur')
    },
    async handleFileInput($event) {
      const files = Array.from($event.target.files)
      this.sendImg(files)
    },
    async dragFile(e) {
      e.preventDefault()
      const { files } = e.dataTransfer
      this.sendImg(files)
    },
    async sendImg(files) {
      // eslint-disable-next-line no-undef, no-plusplus
      for (let i = 0; i < files.length; i++) {
        // eslint-disable-next-line no-undef
        const file = files[i]
        if (this.fileProv.length < 3) {
          if (
            ['image/jpeg2000', 'image/jpeg', 'image/jpg', 'image/png'].includes(file.type)
          && !file.name.includes('jfif')
          && file.size < this.maxFileSize
          ) {
            this.isDisableSubmitClaimRetur = true
            // eslint-disable-next-line no-await-in-loop
            await this.processFile(file)
          } else {
            this.$toast_error({ message: 'Pastikan file sesuai format & tidak lebih dari 2MB', timeout: 2000 })
          }
        }
      }
    },
    async processFile(file) {
      await this.uploadImg(file)
    },
    async uploadImg(file) {
      const formData = new FormData()
      formData.append('file', file)
      await this.$http_new.post('komship/api/v1/claims/upload-img/temp', formData)
        .then(response => {
          const { data } = response.data
          this.fileProv.push(data)
          this.isDisableSubmitClaimRetur = false
        })
        .catch(err => {
          this.isDisableSubmitClaimRetur = false
          this.$toast_error({ message: 'Gagal upload image', timeout: 2000 })
        })
    },
    removeFile(index) {
      this.fileProv.splice(index, 1)
    },
    submitClaimRetur() {
      this.isUploading = true
      const params = {
        ticket_id: Number(this.ticketId),
        order_id: this.orderId,
        type: 'retur',
        images_id: this.fileProv.map(file => file.id),
      }

      this.$http_new
        .post('komship/api/v1/claims', params)
        .then(() => {
          this.isUploading = false
          this.closePopupClaimRetur()
          this.$emit('open-success-modal')
        })
        .catch(error => {
          if (error.response.data.meta.code === 409) {
            this.closePopupClaimRetur()
            this.$emit('modal-have-claim-retur')
          } else {
            this.isUploading = false
            this.$toast_error({ message: 'Gagal mengajukan claim', timeout: 2000 })
          }
        })
        .finally(() => {
        })
    },
  },
}
</script>
