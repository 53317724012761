<template>
  <ModalConfirm
    id="modal-success-claim"
    title="Ajukan Claim Paket"
    message="Claim paket kamu telah kami terima dan akan segera direview"
    :loading-confirm-button="false"
    no-close-on-backdrop
    no-close-on-esc
  >
    <template #icon>
      <img
        src="https://storage.googleapis.com/komerce/core/icon-popup-success.png"
        alt="Komerce"
        width="100"
      >
    </template>

    <template #footer>
      <div class="w-full flex justify-center">
        <b-button
          variant="primary"
          class="basis-[50%] rounded-[8px!important]"
          @click="$emit('on-click-ok')"
        >Lihat Tiket</b-button>
      </div>
    </template>
  </ModalConfirm>
</template>

<script>
import ModalConfirm from '@/views/components/modal/ModalConfirm.vue'

export default {
  components: { ModalConfirm },
}
</script>
