/* eslint-disable default-case */
/* eslint-disable no-lone-blocks */
/* eslint-disable global-require */
/* eslint-disable import/no-unresolved */
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  VBToggle,
  BProgress,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import HistoryShippingOrder from '@core/components/popup-lacak-resi/HistoryShippingOrder.vue'
import PopoverInfo from '@/views/components/popover/PopoverInfo.vue'
import {
  getMessaging,
  getToken,
  onMessage,
} from 'firebase/messaging'
import { initializeApp } from 'firebase/app'
import moment from 'moment'
import secureLs from '@/libs/secureLs'
import { getMessageClaims, anotationClaimsClass } from '@/libs/label-claims'
import ModalConfirm from '@/views/components/modal/ModalConfirm.vue'
import ModalSubmitClaim from './ModalSubmitClaim.vue'
import ModalSuccessClaim from './ModalSuccessClaim.vue'
import ModalSubmitRetur from './component/ModalSubmitRetur.vue'
import ModalSuccessSubmitRetur from './component/ModalSuccessSubmitRetur.vue'
import ModalHaveClaimRetur from './component/ModalHaveClaimRetur.vue'

window.onload = () => {
  const theElement = document.getElementById('chatFocusing')
  const scrollToBottom = node => {
    // eslint-disable-next-line no-param-reassign
    node.scrollTop = node.scrollHeight
  }
  scrollToBottom(theElement)
}

const firebaseConfig = {
  apiKey: 'AIzaSyCPYJYeP-9_G3S5MOV_-8QPDSmxF8dj84g',
  authDomain: 'komship-ticketing.firebaseapp.com',
  projectId: 'komship-ticketing',
  storageBucket: 'komship-ticketing.appspot.com',
  messagingSenderId: '669211426801',
  appId: '1:669211426801:web:55bca3d2dac7238b298e50',
}

const app = initializeApp(firebaseConfig)

const messaging = getMessaging()

export default {
  components: {
    PopoverInfo,
    HistoryShippingOrder,
    BProgress,
    ModalConfirm,
    ModalSubmitClaim,
    ModalSuccessClaim,
    ModalSubmitRetur,
    ModalSuccessSubmitRetur,
    ModalHaveClaimRetur,
  },
  data() {
    return {
      ticketId: this.$route.params.ticket_id,
      loadingDataDetail: true,

      ticketStatus: null,
      orderStatus: '',
      ticketNo: '',
      noResi: '',
      ekspedisi: '',
      seller: '',
      ticketType: '',
      customerName: '',
      description: '',
      files: [],

      loadingDataChat: false,
      messages: [],
      chatItem: '',
      fcmToken: '',
      fileChat: null,
      chatFileMode: false,
      imageFileChat: null,
      imageInitialFile: null,
      imageFile: null,
      itemsImageInitialFile: [],

      loadingCancelTicket: false,

      userId: secureLs.getItem('userData'),
      detailOrderMode: false,
      orderId: 0,

      visible: true,

      orderData: [],
      itemAwb: [],
      listAwb: '',
      moment,

      transactionValue: '',
      isLoading: false,

      stars: [
        {
          value: 1,
          active: false,
        },
        {
          value: 2,
          active: false,
        },
        {
          value: 3,
          active: false,
        },
        {
          value: 4,
          active: false,
        },
        {
          value: 5,
          active: false,
        },
      ],
      ratingUser: null,
      dateRating: null,

      itemButtonWaCustomer: 'https://storage.googleapis.com/komerce/assets/icons/wa-notactive.svg',
      customerPhone: '',

      request: null,

      claimReturItem: null,

      statusClaimLost: null,
      isHaveTicketClaimLost: false,
      claimLostData: null,
      claimReturData: null,

      dateClaimRetur: null,

      buttonClaimRetur: false,
      infoClaimRetur: false,

      infoLinkClaimRetur: null,
      claimReturIsActive: false,
      statusPriority: 0,
      orderStatusIsRetur: false,

      dataAwb: {},

      reasonValue: '',

      valueRating: 0,

      deliveryTrial: 0,
      packageHold: 0,

      showRemind: false,
      requestRemind: true,
      requestUrgent: 0,
      loadingRequestUrgent: false,
      dataClaims: [],
      getMessageClaims,
      anotationClaimsClass,
    }
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  created() {
    this.receiveMessage()
  },
  async mounted() {
    this.fetchDataFirebase()
    this.fetchDetailTicket()
    const theElement = document.getElementById('chatFocusing')
    if (theElement !== null) {
      const scrollToBottom = node => {
      // eslint-disable-next-line no-param-reassign
        node.scrollTop = node.scrollHeight
      }
      scrollToBottom(theElement)
    }
    this.readChat()
  },
  computed: {
    statusClaimLostData() {
      let message = ''
      let classname = ''
      let footerMessage = 'Belum Ajukan Claim'

      switch (this.statusClaimLost) {
        case 0: {
          message = 'Claim Paket Hilang Sudah Diajukan'
          classname = 'text-warning'
          if (this.isHaveTicketClaimLost) {
            footerMessage = 'Claim Sedang Direview'
          }
        }
          break

        case 1:
          message = 'Claim Paket Hilang Disetujui'
          classname = 'text-success'
          footerMessage = 'Claim Disetujui'
          break

        case 2:
          message = 'Claim Paket Hilang Ditolak'
          classname = 'text-danger'
          footerMessage = 'Claim Ditolak'
          break
      }

      return {
        message, classname, footerMessage,
      }
    },
    isShowClaimableButton() {
      return Number(this.dataAwb.count_stuck_day) >= 15
    },
  },
  methods: {
    fetchDetailTicket() {
      this.infoClaimRetur = false
      this.$http_komship.get(`v1/ticket-partner/detail/${this.ticketId}`)
        .then(async response => {
          const { data } = response.data
          this.dataClaims = data.claim_data.filter(item => item.claim_type === 'hilang' || item.claim_type === 'retur' || item.claim_type === 'paksa_diterima' || item.claim_type === 'paksa_rts')

          if (this.dataClaims.length !== 0) {
            this.claimReturItem = this.dataClaims.some(item => item.claim_type === 'retur') ? this.dataClaims.find(item => item.claim_type === 'retur').claim_status : null
            this.statusClaimLost = this.dataClaims.some(item => item.claim_type === 'hilang') ? this.dataClaims.find(item => item.claim_type === 'hilang').claim_status : null
            this.infoClaimRetur = this.dataClaims.some(item => item.claim_type === 'retur')
            this.isHaveTicketClaimLost = this.dataClaims.some(item => item.claim_type === 'hilang')
            this.dateClaimRetur = this.dataClaims.some(item => item.claim_type === 'retur') ? this.dataClaims.find(item => item.claim_type === 'retur').created_at : null
          }

          if (data.order_status === 'Retur' || data.order_status === 'Diterima') {
            if (data.payment_method === 'COD' && this.claimReturItem === null) {
              this.buttonClaimRetur = true
            }
          }
          if (data.payment_method === 'COD') this.claimReturIsActive = true
          if (data.order_status === 'Retur') this.orderStatusIsRetur = true
          this.ticketStatus = data.ticket_status
          this.orderStatus = data.order_status
          this.ticketNo = data.ticket_no
          this.ticketType = data.ticket_type
          this.customerName = data.customer_name
          this.customerPhone = data.customer_phone
          this.description = data.description
          this.seller = data.partner_name
          this.ekspedisi = data.shipping
          this.noResi = data.no_resi
          this.files = data.file
          this.messages = data.history_ticket
          this.orderId = await data.order_id
          this.loadingDataDetail = false
          this.ratingUser = data.rating_user
          this.statusPriority = data.status_priority
          this.requestRemind = data.request_urgent === 0
          if (data.rating_user !== null) this.previewRating(data.rating_user.rating)
          this.dateRating = data.date_updated
          this.transactionValue = data.grand_total
          setTimeout(() => {
            const theElement = document.getElementById('chatFocusing')
            const scrollToBottom = node => {
              // eslint-disable-next-line no-param-reassign
              node.scrollTop = node.scrollHeight
            }
            scrollToBottom(theElement)
          }, 500)
          this.getTicketTrip()
          this.slaMonitoring()
          this.getRemind()
          this.requestUrgent = data.request_urgent === 1
        })
        .catch(err => {
          this.loadingDataDetail = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err,
              variant: 'danger',
            },
          }, 2000)
        })
    },
    storeChat() {
      Notification.requestPermission().then(permission => {
        if (!('permission' in Notification)) {
          Notification.permission = permission
        }
        if (permission === 'granted') {
          this.loadingDataChat = true
          const formData = new FormData()
          if (this.chatItem) formData.append('message', this.chatItem)
          formData.append('ticket_id', Number(this.ticketId))
          if (this.itemsImageInitialFile.length > 1) {
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < this.itemsImageInitialFile.length; i++) {
              formData.append('file[]', this.itemsImageInitialFile[i])
            }
          } else {
            formData.append('file[]', this.itemsImageInitialFile[0])
          }
          this.$http_komship.post('/v2/ticket-partner/store-chat', formData)
            .then(() => {
              fetch('https://fcm.googleapis.com/fcm/send', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: 'key=AAAAm9Ab__E:APA91bF7K9M9Adw_2DbHcySgjESrZjV_i6QCURNX4TaHwo8ah3VX1j3e_DJb3CV7cdEtgIXkfTAHrNqvuAwMElQMnflI6z0_E1BcAX9OPfIVCZ4ewiOq1N2dhHWYcsBQ7Nu4nFFQF8-2',
                },
                body: JSON.stringify({
                  to: this.fcmToken,
                }),
              })
                .then(() => {
                  this.chatItem = ''
                  this.itemsImageInitialFile = []
                  this.loadingDataChat = false
                  this.chatFileMode = false
                })
                .catch(err => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Failure',
                      icon: 'AlertCircleIcon',
                      text: err,
                      variant: 'danger',
                    },
                  }, 2000)
                  this.loadingDataChat = false
                })
            }).catch(err => {
              this.loadingDataChat = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Failure',
                  icon: 'AlertCircleIcon',
                  text: err,
                  variant: 'danger',
                },
              }, 2000)
            })
        } else {
          this.loadingDataChat = true
          const formData = new FormData()
          if (this.chatItem) formData.append('message', this.chatItem)
          formData.append('ticket_id', Number(this.ticketId))
          if (this.itemsImageInitialFile.length > 1) {
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < this.itemsImageInitialFile.length; i++) {
              formData.append('file[]', this.itemsImageInitialFile[i])
            }
          } else {
            formData.append('file[]', this.itemsImageInitialFile[0])
          }
          this.$http_komship.post('/v2/ticket-partner/store-chat', formData)
            .then(() => {
              this.chatItem = ''
              this.itemsImageInitialFile = []
              this.loadingDataChat = false
              this.chatFileMode = false
              this.fetchDetailTicket()
            }).catch(err => {
              this.loadingDataChat = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Failure',
                  icon: 'AlertCircleIcon',
                  text: err,
                  variant: 'danger',
                },
              }, 2000)
            })
        }
      })
    },
    statusTicketVariant(data) {
      let resultVariant = 'light-primary'
      if (data === 0) {
        resultVariant = 'light-danger'
      } else if (data === 1) {
        resultVariant = 'light-primary'
      } else if (data === 2) {
        resultVariant = 'light-warning'
      } else if (data === 3) {
        resultVariant = 'light-success'
      } else if (data === 4) {
        resultVariant = 'light-secondary'
      }
      return resultVariant
    },
    orderStatusVariant(data) {
      let result = ''
      if (data === 'Diajukan') {
        result = 'light-primary'
      } else if (data === 'Dipacking') {
        result = 'light-info'
      } else if (data === 'Dikirim') {
        result = 'light-warning'
      } else if (data === 'Diterima') {
        result = 'light-success'
      } else if (data === 'Retur') {
        result = 'light-danger'
      } else if (data === 'Batal') {
        result = 'light-dark'
      }
      return result
    },
    orderStatusTextVariant(data) {
      let result = 'light-primary'
      if (data === 'Diajukan') {
        result = 'light-primary'
      } else if (data === 'Dipacking') {
        result = 'light-info'
      } else if (data === 'Dikirim') {
        result = 'light-warning'
      } else if (data === 'Diterima') {
        result = 'light-success'
      } else if (data === 'Retur') {
        result = 'light-danger'
      } else if (data === 'Rusak') {
        result = 'light-danger'
      } else if (data === 'Hilang') {
        result = 'light-danger'
      } else if (data === 'Batal') {
        result = 'light-dark'
      }
      return result
    },
    convertTicketStatus(data) {
      let result = 'memuat...'
      if (data === 0) {
        result = 'Perlu Tindak Lanjut'
      } else if (data === 1) {
        result = 'Belum Diproses'
      } else if (data === 2) {
        result = 'Sedang Diproses'
      } else if (data === 3) {
        result = 'Selesai'
      } else if (data === 4) {
        result = 'Batal'
      }
      return result
    },
    fetchDataFirebase() {
      Notification.requestPermission().then(permission => {
        if (!('permission' in Notification)) {
          Notification.permission = permission
        }
      }, rejection => {
        // handle rejection here.
      })
      getToken(messaging, { vapidKey: 'BLZr38POWZ6vwjTUx4v2vlPHK-3fiI-DMPY18tAbu1dpchDiAYMyR7l2PE3WbH5hOM55X2zBR_C-5BLrpUA1-ZM' }).then(currentToken => {
        if (currentToken) {
          this.fcmToken = currentToken
          Notification.requestPermission().then(permission => {
            if (permission === 'denied' || permission === 'default') {
              this.$refs['modal-alert-notification'].show()
              this.changeAttr()
            }
            if (!('permission' in Notification)) {
              Notification.permission = permission
            }
          })
          this.$http
            .post('/user/update-fcm-token', {
              user_id: this.userId.id,
              fcm_token: currentToken,
            })
        } else {
          console.log('No registration token available. Request permission to generate one.')
        }
      }).catch(err => {
        this.$refs['modal-alert-notification'].show()
        this.changeAttr()
      })
    },
    fileUrl: file => (file ? URL.createObjectURL(file) : null),
    receiveMessage() {
      try {
        onMessage(messaging, payload => {
          this.loadingDataChat = true
          this.fetchDetailTicket()
          this.fetchDataFirebase()
          setTimeout(() => {
            this.loadingDataChat = false
          }, 300)
        })
      } catch (err) {
        setTimeout(() => {
          this.loadingDataChat = false
        }, 300)
      }
    },
    handleCloseAlertCancel() {
      this.$refs['alert-cancel-ticket'].hide()
    },
    cancelTicket() {
      this.$refs['alert-cancel-ticket'].show()
    },
    submitCancelTicket() {
      this.loadingCancelTicket = true
      this.$http_komship.delete(`/v1/ticket-partner/cancel/${this.ticketId}`)
        .then(() => {
          this.fetchDetailTicket()
          this.loadingCancelTicket = false
          this.$refs['alert-cancel-ticket'].hide()
        }).catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err,
              variant: 'danger',
            },
          }, 2000)
          this.loadingCancelTicket = false
        })
    },
    copyTicket(data) {
      /* Copy the text inside the text field */
      navigator.clipboard.writeText(data)

      /* Alert the copied text */
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Nomor Ticket ${data} berhasil dicopy`,
          icon: 'AlertCircleIcon',
          variant: 'warning',
        },
      }, 1000)
    },
    copyResi(data) {
      /* Copy the text inside the text field */
      navigator.clipboard.writeText(data)

      /* Alert the copied text */
      this.$toast({
        component: ToastificationContent,
        props: {
          title: ` Resi ${data} berhasil dicopy`,
          icon: 'AlertCircleIcon',
          variant: 'warning',
        },
      }, 1000)
    },
    onChangeFile(event) {
      event.target.files.forEach(this.myFile)
    },
    myFile(data) {
      this.itemsImageInitialFile.push(data)
    },
    putFileChat(event) {
      event.target.files.forEach(this.myFile)
      this.chatFileMode = true
    },
    addFileChat(event) {
      event.target.files.forEach(this.myFile)
    },
    removeFileChat(data) {
      const findIndexObj = this.itemsImageInitialFile.findIndex(items => items.name === data.name)
      this.itemsImageInitialFile.splice(findIndexObj, 1)
      if (this.itemsImageInitialFile.length === 0) {
        this.chatFileMode = false
      }
    },
    disableButtonCancel(data) {
      let result = false
      if (data === 3) {
        result = true
      } else if (data === 4) {
        result = true
      }
      return result
    },
    getValueFile(value) {
      return value.replaceAll('/', '/ ')
    },
    handleDetailOrder() {
      this.detailOrderMode = true
    },
    updateDetailOrderMode() {
      this.detailOrderMode = false
    },
    handleCloseAlert() {
      this.$refs['modal-alert-notification'].hide()
    },
    messageAdapter(chat) {
      let link = null
      const convertToBoolean = text => {
        const anchorRegex = /<a\s+(?:[^>]*?\s+)?href=['"](.*?)['"][^>]*?>\[Lihat Bukti\]<\/a>/
        return anchorRegex.test(text)
      }
      const isProof = convertToBoolean(chat)
      if (typeof (chat) !== 'object' && !isProof) {
        const urlify = text => {
          const urlRegex = /(https?:\/\/[^\s]+)/g
          return text.replace(urlRegex, url => `<a href="${url}" target="_blank" class="text-white">${url}</a>`)
        }
        link = urlify(chat)
      } else {
        link = chat
      }
      return link
    },
    readChat() {
      const formData = new FormData()
      formData.append('ticket_id', this.ticketId)
      formData.append('user_id', this.userId.partner_detail.id)
      this.$http_komship.post('/v1/ticket-partner/chat/read-all', formData).then(() => {}).catch(err => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failure',
            icon: 'AlertCircleIcon',
            text: err,
            variant: 'danger',
          },
        }, 2000)
      })
    },
    handleSubmitChat(event) {
      if (event.keyCode === 13 && !event.shiftKey) {
        event.preventDefault()
        if (this.chatItem !== '') {
          this.storeChat()
        }
      }
    },
    formatRibuan(x) {
      if (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      return x
    },
    formatRupiah(x) {
      return `Rp ${this.formatRibuan(x)}`
    },
    goBack() {
      window.close()
    },
    previewRating(data) {
      const findIndexRating = this.stars.findIndex(item => item.value === data)
      this.stars.forEach((item, index) => {
        if (index <= findIndexRating) {
          this.stars[index].active = true
        }
      })
    },
    resetRating() {
      this.stars = [
        {
          value: 1,
          active: false,
        },
        {
          value: 2,
          active: false,
        },
        {
          value: 3,
          active: false,
        },
        {
          value: 4,
          active: false,
        },
        {
          value: 5,
          active: false,
        },
      ]
    },
    submitRating(data) {
      this.valueRating = data
      if (data > 2) {
        const formData = new FormData()
        formData.append('ticket_id', this.ticketId)
        formData.append('user_id', this.userId.partner_detail.id)
        formData.append('rating', data)
        this.$http_komship.post('/v1/ticket-partner/rating/store', formData)
          .then(response => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'CheckIcon',
                text: 'berhasil submit review',
                variant: 'success',
              },
            }, 2000)
            this.fetchDetailTicket()
          })
          .catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: err,
                variant: 'danger',
              },
            }, 2000)
          })
      } else {
        this.$refs['alert-submit-low-rating'].show()
      }
    },
    applyValueTransaction(data) {
      this.transactionValue = data
    },
    handleHoverButtonWa() {
      this.itemButtonWaCustomer = 'https://storage.googleapis.com/komerce/assets/icons/wa-active.svg'
    },
    handleLeaveHoverButtonWa() {
      this.itemButtonWaCustomer = 'https://storage.googleapis.com/komerce/assets/icons/wa-notactive.svg'
    },
    handleClickWaCustomer() {
      if (this.customerPhone.charAt(0) === '0') {
        window.open(`https://api.whatsapp.com/send?phone=62${this.customerPhone.slice(1, this.customerPhone)}`, '_blank')
      } else if (this.customerPhone.charAt(0) === '6' && this.customerPhone.charAt(1) === '2') {
        window.open(`https://api.whatsapp.com/send?phone=${this.customerPhone}`, '_blank')
      } else {
        window.open(`https://api.whatsapp.com/send?phone=62${this.customerPhone}`, '_blank')
      }
    },
    openPopupClaimRetur() {
      const params = {
        title: 'Formulir Pengajuan Claim',
        typeClaims: 'Retur',
        ticketId: this.ticketId,
        orderId: this.orderId,
        typeParams: 'retur',
      }
      this.$router.push({ name: 'pengajuan-order', params })
    },
    handleBackPopupClaimRetur() {
      this.$refs['popup-have-submitted-retur-claim'].hide()
    },
    nextSuccessReturClaim() {
      this.fetchDetailTicket()
      this.$bvModal.hide('popup-success-claim-retur')
    },
    getLinkOnNotes(chat) {
      let link = null
      if (chat !== null) {
        const urlify = text => {
          const urlRegex = /(https?:\/\/[^\s]+)/g
          return text.replace(urlRegex, url => `<a href="${url}" target="_blank" class="text-info">${url}</a>`)
        }
        link = urlify(chat)
      }
      return link
    },
    async changeAttr() {
      const element = document.getElementsByTagName('body')[0].className

      await (element === 'modal-open')
      document.querySelectorAll('div.modal-content')[0].removeAttribute('tabindex')
    },
    getTicketTrip() {
      const resi = this.noResi
      this.loading = true
      this.$http_komship
        .post(`v1/global/check-awb?awb=${resi}`)
        .then(res => {
          const { data } = res
          this.dataAwb = data.data
          this.loading = false
        })
        .catch(err => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: err.response.data.message,
                variant: 'danger',
              },
            },
            2000,
          )
          this.loading = false
        })
    },
    submitReasonRating() {
      const formData = new FormData()
      formData.append('ticket_id', this.ticketId)
      formData.append('user_id', this.userId.partner_detail.id)
      formData.append('rating', this.valueRating)
      formData.append('feedback', this.reasonValue)
      this.$http_komship.post('/v1/ticket-partner/rating/store', formData)
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'berhasil submit review',
              variant: 'success',
            },
          }, 2000)
          this.fetchDetailTicket()
          this.$refs['alert-submit-low-rating'].hide()
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err,
              variant: 'danger',
            },
          }, 2000)
        })
    },
    closeSubmitLowRating() {
      this.$refs['alert-submit-low-rating'].hide()
    },
    slaMonitoring() {
      this.$http_komship.get(`/v1/partner/sla/${this.noResi}`).then(response => {
        const { data } = response.data
        this.deliveryTrial = data.delivery_attempt
        this.packageHold = data.hold_package_days
      }).catch(error => {
        if (error.response.data.code !== 400) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: error,
              variant: 'danger',
            },
          }, 2000)
        }
      })
    },
    getRemind() {
      const currentDate = new Date()
      const dateUpdated = new Date(this.dateRating)
      const timeDifference = currentDate - dateUpdated
      const twelveHoursInMilliseconds = 12 * 60 * 60 * 1000

      this.showRemind = timeDifference > twelveHoursInMilliseconds
    },
    setRemind() {
      this.loadingRequestUrgent = true
      const params = {
        ticket_id: Number(this.ticketId),
        request_urgent: 1,
      }
      this.$http_komship.post('/v1/ticket-partner/set-request-urgent', params)
        .then(() => {
          this.loadingRequestUrgent = false
          this.showRemind = false
          this.requestRemind = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'Berhasil follow up tiket. Tim kami akan segera membantu',
              variant: 'success',
            },
          }, 500)
          this.fetchDetailTicket()
        }).catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: 'Gagal follow up tiket',
              variant: 'danger',
            },
          }, 500)
          this.loadingRequestUrgent = false
        })
    },
    closeWaitingClaimReview() {
      this.$bvModal.hide('modal-success-claim')
      this.$bvModal.hide('modal-ajukan')
      this.fetchDetailTicket()
    },
    requestRemindUs() {
      this.loadingRequestUrgent = true
      this.$http_komship.get('/v1/ticket-partner/set-request-urgent', {
        params: {
          ticket_id: this.ticketId,
          request_urgent: 1,
        },
      }).then(() => {
        this.fetchDetailTicket()
        this.loadingRequestUrgent = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CheckIcon',
            text: 'Berhasil follow up tiket. Tim kami akan segera membantu',
            variant: 'success',
          },
        }, 500)
      }).catch(err => {
        this.loadingRequestUrgent = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failure',
            icon: 'AlertCircleIcon',
            text: err,
            variant: 'danger',
          },
        }, 2000)
      })
    },
    orderStatusText(status) {
      if (!status) return 'memuat...'
      if (status === 'Hilang') return 'Paket Hilang'
      if (status === 'Rusak') return 'Paket Rusak'
      return status
    },
    getColorInfoClaimRetur(data) {
      let result = ''
      if (data?.claim_status === 0) {
        result = 'text-warning'
      } else if (data?.claim_status === 1) {
        result = 'text-success'
      } else if (data?.claim_status === 2) {
        result = 'text-danger'
      }
      return result
    },
    getInfoClaimRetur(data) {
      const claimTypeMap = {
        paksa_diterima: 'Laporan Paket Diterima',
        paksa_rts: 'Laporan Retur Diterima',
        retur: 'Claim Retur',
      }

      const statusClaimMap = {
        0: ' on Review',
        1: ' Disetujui',
        2: ' Ditolak',
      }

      const claimType = claimTypeMap[data.claim_type]
      const statusClaim = statusClaimMap[data?.claim_status]

      return claimType + statusClaim
    },
    openModalSuccessSubmitRetur() {
      this.$bvModal.show('popup-success-claim-retur')
    },
    openModalHaveClaimRetur() {
      this.$bvModal.show('popup-have-submitted-retur-claim')
    },
    getTooltipClaim(stuckDay) {
      const result = `Paketmu sudah stuck ${stuckDay} hari.\n`
      let text = ''
      if (stuckDay <= 30 || (this.ekspedisi !== 'IDEXPRESS' && stuckDay <= 60)) {
        text = 'Memungkinkan kamu bisa claim paket hilang, lalu saldo akan bertambah sesuai penghasilan order'
      } else {
        text = `Sudah melewati masa claim (maksimal mengajukan claim ${this.ekspedisi === 'IDEXPRESS' ? 30 : 60} Hari setelah stuck dari status perjalanan terakhir)`
      }
      return result + text
    },
  },
}
