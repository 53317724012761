<template>
  <div>
    <b-modal
      id="popup-success-claim-retur"
      centered
      hide-header
      hide-footer
    >
      <b-row class="justify-content-center text-center mb-2 mt-2">
        <h4 class="text-black">
          <strong>
            Claim Telah Diajukan
          </strong>
        </h4>
      </b-row>

      <b-row
        class="mx-2 mb-2 p-1"
        style="background-color: #DCF3EB;"
      >
        <b-row class="align-items-center ml-1 mb-1">
          <span
            style="color: #34A770; font-size: 18px;"
            class="mr-50"
          >
            <strong>
              Complete
            </strong>
          </span>
          <b-img
            src="https://storage.googleapis.com/komerce/assets/svg/tick-circle.svg"
            width="25"
          />
        </b-row>
        <span class="ml-1 text-black">
          Claim retur sukses diclaim. Tim kami akan memverifikasi dalam 1X24 jam. Kamu bisa pantau status
          klaimnya
          pada <strong>detail ticket</strong>.
        </span>
      </b-row>

      <b-row class="mx-2 mb-2">
        <b-button
          variant="primary"
          block
          @click="nextSuccessReturClaim"
        >
          Selanjutnya
        </b-button>
      </b-row>
    </b-modal>
  </div>
</template>

<script>

export default {
  methods: {
    nextSuccessReturClaim() {
      this.$emit('next-success-retur-claim')
    },
  },
}
</script>
