<template>
  <ModalConfirm
    id="modal-ajukan"
    title="Ajukan Claim Paket"
    label-confirm-button="Claim"
    label-cancel-button="Batal"
    :loading-confirm-button="isLoading"
    @on-click-cancel-button="$bvModal.hide('modal-ajukan')"
    @on-click-confirm-button="claimLostTicket"
  >
    <template #message>
      <p class="mb-0 text-[#333333]">
        Kamu hampir selesai!
      </p>
      <p class="mb-0 text-[#333333]">
        Apakah kamu yakin ingin mengajukan klaim paket ini?
      </p>
    </template>
  </ModalConfirm>
</template>

<script>
import ModalConfirm from '@/views/components/modal/ModalConfirm.vue'

export default {
  components: { ModalConfirm },
  // eslint-disable-next-line vue/require-default-prop
  props: { orderId: Number, ticketId: String },
  data: () => ({
    isLoading: false,
  }),
  methods: {
    claimLostTicket() {
      this.isLoading = true

      const params = {
        order_id: this.orderId,
        type: 'hilang',
        ticket_id: Number(this.ticketId),
      }

      this.$http_new
        .post('komship/api/v1/claims', params)
        .then(() => {
          this.$bvModal.show('modal-success-claim')
        })
        .catch(error => {
          const text = error.response.data.meta.code === 409 ? 'Resi ini sudah pernah di ajukan claim' : 'Gagal mengajukan claim'
          this.$toast_error({ message: text, timeout: 2000 })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
