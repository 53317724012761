<template>
  <div>
    <b-modal
      id="popup-have-submitted-retur-claim"
      centered
      hide-header
      hide-footer
    >
      <b-row class="justify-content-center text-center mb-2 mt-2">
        <h4 class="text-black">
          <strong>
            Claim Sudah diajukan
          </strong>
        </h4>
      </b-row>

      <b-row class="mx-2 mb-2">
        <span class="text-center">
          Kamu udah ngajuin claim di id ticket ini,
          jika kamu mau ngasih data tambahan bisa kamu upload di lampiran chat ticket ini ya...
        </span>
      </b-row>

      <b-row class="mx-2 mb-2">
        <b-button
          variant="primary"
          block
          @click="handleBackPopupClaimRetur"
        >
          Kembali
        </b-button>
      </b-row>
    </b-modal>
  </div>
</template>

<script>

export default {
  methods: {
    handleBackPopupClaimRetur() {
      this.$bvModal.hide('popup-have-submitted-retur-claim')
    },
  },
}
</script>
